import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {doubleOptIn} from '../state/actions/userManagement';

import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section/Section';
import Meta, {appendSeoTitleSuffix} from '../components/Layout/Meta';
import {LoginStyled} from '../components/Container/Login/styles';

import {PageTemplateStyled} from '../templates/styles';
import Text from '../components/Elements/Text/Text';
import Headline from '../components/Elements/Headline/Headline';
import Button from '../components/Elements/Button/Button';

const VerifyUser = ({location, handleVerify}) => {
	const params = new URLSearchParams(location.search);
	const userId = params.get('userId');
	const code = params.get('code');
	handleVerify({userId, code});
	return (
		<>
			<Meta title={appendSeoTitleSuffix('Freischaltung Deines Benutzerkontos')} />
			<Layout location={location}>
				<PageTemplateStyled>
					<Section showPadding>
						<Headline as="h1" center text="Vielen Dank für die Bestätigung!" />
						<Text
							content="Du kannst dich ab jetzt mit deinem Benutzernamen und
								Passwort anmelden."
							center
							maxWidth={850}
						/>
					</Section>
					<LoginStyled>
						<Button to="/login" type="link" text="Zur Anmeldung" />
					</LoginStyled>
				</PageTemplateStyled>
			</Layout>
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({
	handleVerify: (data) => dispatch(doubleOptIn(data)),
});

VerifyUser.propTypes = {
	handleVerify: PropTypes.func.isRequired,
	location: PropTypes.shape({pathname: PropTypes.string, search: PropTypes.string}).isRequired,
};

export default connect(null, mapDispatchToProps)(VerifyUser);
